@import '../../../styles/apx.scss';

.datepicker {

  &__table {

    width: 100%;
    margin: 0 auto;
    padding: $apx-2 $apx-8 $apx-8;
    user-select: none;
    border-spacing: $apx-4;
    border-collapse: separate;

  }

  .calendar-panel {

    &-btn {

      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 10px;
      user-select: none;

      &__arrow {

        min-width: 28px;
        min-height: 22px;
        justify-content: center;
        cursor: pointer;

      }

      & svg {

        margin: 0 7px;
        width: 9px;
        fill: #616161;
      
      }

      & div {
        display: flex;
        align-items: center;
      }

    }

    &__monthname {

      min-width: $apx-71;
      justify-content: center;

    }

  }

  .panel-button-select {

    display: flex;
    position: relative;
    padding: $apx-7 $apx-14;
    background: #F6F6F6;
    border: 1px solid #E9E9E9;
    border-radius: 4px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16.1818px;
    line-height: 20px;
    
    &-icon {

      position: absolute;
      top: 8px;
      right: 8px;

    }

    & > div {

      display: flex;
      justify-content: center;
      align-items: center;
      color: #959595;
      cursor: pointer;
      white-space: nowrap;
      font-size: $apx-16;
      line-height: $apx-20;

      &:not(div:first-child) { margin-left: $apx-5; }

    }

  }

  &--active {

    .panel-button-select {

      background: #69D167;      
      border: 1px solid #69D167;

      & > div { 
        
        font-weight: 700 !important;
        color: #fff !important;

      }

    }

  }

  .container-panel-btn {
    margin: 0 20px;
    margin-top: 26px;
  }
   
  .in-prev-month,
  .in-next-month {
    color: transparent;
    background-color: transparent;
    border-radius: 10%;
    opacity: 0.4;
    border: none;
    text-align: center;
  }

  .title { text-align: center; }

  .calendar-info {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .calendar {
    
    position: absolute;
    top: -4.43751vw;
    right: 0;
    z-index: 2;
    padding-top: $apx-15 $apx-10;
    background: rgba(255, 255, 255, 0.95);
    border: 2px solid #F4F4F4;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    thead tr th { 
      
      min-width: 34px;
      height: 34px; 
    
    }

    tr {

      font-family: Roboto;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: center;
      color: #616161;

    }

    .day {

      color: #616161;
      height: 31.636363983154297px;
      min-width: 34.272727966308594px;
      font-family: Roboto;
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      text-align: center;
      border: 0.878788px solid #F0F0F0;
  
      &:hover {
        opacity: 0.9;
        cursor: pointer;
      }
  
      &.selected {

        color: #FFFFFF;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);
        background-color: #ffd183;
        border: 2px solid #ffa000;
  
      }
  
    }

  }

  .calendar-choice {

    fieldset {

      position: relative;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 139px;
      border: 1px solid #494949;
      margin-left: 33px;

    }

    legend {

      padding: 0px 12px;
      font-family: Roboto;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #8f8f8f;

    }

  }

  .time-btn {
    & input {
      width: 70px;
      height: 42px;
      color: #68e4ff;
      padding: 8px;
      background-color: var(--color-black);
      border: 1px solid #3d3d3d;
      font-family: Roboto;
      font-size: 18px;
      line-height: 21px;
    }
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: Roboto;
    margin-left: 28px;
    margin-top: 14px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .date-choice {

    height: 54px;
    margin-top: 18px;
    border-top: 1px solid #414141;
    border-bottom: 1px solid #414141;
    
  }

  .date-choice * {

    color: #68e4ff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;

  }

  .date-choice svg {
    width: 12px !important;
    height: 12px !important;
  }

  .calendar-table {

    & th {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: #ffffff2b;
      padding-bottom: 15px;
    }

    & .day {
      height: 39.491493225097656px;
      width: 40.32099533081055px;
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
    }

  }

  .mobile-calendar {
    z-index: 5;
    display: none;
    position: fixed;
    bottom: 71px;
    right: 16px;
  }
  .period {
    max-width: 305px;
  }

}

@media screen and ( max-width: 800px ) {

  .datepicker {

    .datepicker__table {

      border-spacing: $apx-m-4;
      padding: $apx-m-2 $apx-m-8 $apx-m-8;

    }

    .calendar-panel {
      
      top: $apx-m-80;
      position: relative;

      &-btn {

        width: $apx-m-340;
        height: $apx-m-35;
        padding-top: $apx-m-8;
        display: flex !important;
        justify-content: space-between !important;
        margin-bottom: $apx-m-10;

        &__arrow {

          min-width: $apx-m-30;
          min-height: $apx-m-30;

        }

        & svg {

          margin: 0 $apx-m-4;
          width: $apx-m-6;
        
        }

      }

      &__monthname {

        min-width: $apx-m-71;
  
      }

    }

    .panel-button-select {

      width: 36.000045vw;
      display: grid !important;
      place-items: center;
      padding: 5px 0 !important;
      height: $apx-m-50;
      border-radius: $apx-m-4 !important;
      font-size: $apx-m-14 !important;
      line-height: $apx-m-18 !important;
      margin-left: -$apx-m-4;
      
      &-icon {

        top: $apx-m-4;
        right: $apx-m-4;

      }


      & > div {

        font-size: $apx-m-12 !important;
        line-height: $apx-m-13 !important;
        padding: $apx-m-3 $apx-m-5 !important;

        & > div {
    
          &:not(div:first-child) { margin-left: $apx-5; }
    
        }

        &:not(div:first-child) { margin-left: $apx-5; }

      }

    }

    .container-panel-btn {
      margin: 0 20px;
      margin-top: 26px;
      width: $apx-m-300 !important;
    }
    
    .in-prev-month,
    .in-next-month {
      color: transparent;
      background-color: transparent;
      border-radius: 10%;
      opacity: 0.4;
      text-align: center;
      border: none;
    }

    .title { text-align: center; }

    .calendar-info {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    }

    .calendar {
      
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: fixed;
      margin-top: 0;
      padding-top: $apx-m-110;
      border-radius: $apx-m-6;
      border: $apx-m-2 solid #F4F4F4;
      box-shadow: 0 $apx-m-2 $apx-m-12 rgba(0, 0, 0, 0.04);

      thead tr th { 
        
        min-width: 35px;
        height: 35px; 
      
      }

      tbody { height: $apx-m-50; }

      tr {

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #616161;

      }

      .day {

        color: #616161;
        height: $apx-m-42;
        min-width: 34.272727966308594px;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 9px;
        text-align: center;
        border: 0.878788px solid #F0F0F0;
    
        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
    
        &.selected {

          font-weight: 600;
          font-size: 14px;
          color: #FFFFFF;
          text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);
          background-color: #ffd183;
          border: 2px solid #ffa000;
    
        }
    
      }

    }

    .calendar-choice {

      fieldset {

        position: relative;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 139px;
        margin-left: 33px;
        border: 1px solid #494949;

      }

      legend {

        padding: 0px 12px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #8f8f8f;

      }

    }

    .time-btn {
      & input {
        width: 70px;
        height: 41px;
        color: #68e4ff;
        padding: 7px;
        background-color: var(--color-black);
        border: 1px solid #3d3d3d;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
      }
      display: flex;
      gap: 10px;
      align-items: center;
      margin-left: 28px;
      margin-top: 14px;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #bcbcbc;
    }

    .date-choice {

      height: 54px;
      margin-top: 18px;
      border-top: 1px solid #414141;
      border-bottom: 1px solid #414141;
      
    }

    .date-choice * {

      color: #68e4ff;
      font-size: 16px;
      line-height: 19px;
      font-style: normal;
      font-weight: 400;

    }

    .date-choice svg {
      width: 12px !important;
      height: 12px !important;
    }

    .calendar-table {

      & th {
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #ffffff2b;
        padding-bottom: 15px;
      }

      & .day {
        height: 39.491493225097656px;
        width: 40.32099533081055px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 11px;
      }

    }

    .mobile-calendar {
      z-index: 5;
      display: none;
      position: fixed;
      bottom: 80px;
      right: 16px;
    }
    .period {
      max-width: 305px;
    }

  }

}