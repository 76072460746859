//подключение apx
@import '../../../styles/apx.scss';
//подключение tailwind
@import '../../../styles/tailwind.scss';

.textfield {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-wrapper {

    margin-bottom: $apx-20;

    p {

      color: #434343;
      font-weight: 400;
      font-size: $apx-20;
      margin-left: $apx-3;
      font-family: Roboto;

    }

    &__field {

      color: #494545;
      font-weight: 400;
      font-size: $apx-22;
      font-family: Roboto;

      background-color: #FDFDFD;
      border: $apx-2 solid #F1F1F1;
      padding: $apx-11 $apx-20;
      border-radius: $apx-10;

      &::placeholder {

        color: #CDCDCD;
        font-weight: 400;
        font-size: $apx-21;
        font-family: Roboto;

      }

    }

  }

}

.resetpassword__text {
  
  user-select: none;
  margin-top: -$apx-16;
  font-size: $apx-18;
  padding: $apx-8 $apx-16;
  border-radius: $apx-6;
  margin-bottom: $apx-17;
  color: #5f411a;
  background: #fff4e0;

  span { 

    cursor: pointer;
    font-size: inherit; 
    text-decoration: underline; 
    color: #e26755;
    font-weight: 500;

    &:hover { color: #ef7462; }

  }

}

.twoinputs {

  .textfield {

    & + .textfield { margin-left: 3.5%; }
    &-wrapper__field { width: 100%; }

  }
  
}

@media screen and (max-width: 800px) {

  .textfield {
  
    &-wrapper {
  
      margin-bottom: $apx-m-12;
  
      p {
  
        margin-left: 0;
        font-size: $apx-m-14;
        line-height: $apx-m-16;
  
      }
  
      &__field {
  
        font-size: $apx-m-15;
        line-height: $apx-m-18;
        border-radius: $apx-m-6;
        padding: $apx-m-5 $apx-m-10;
        border: $apx-m-2 solid #F1F1F1;
  
        &::placeholder {
 
          font-size: $apx-m-15;
          line-height: $apx-m-18;
  
        }
  
      }
  
    }
  
  }
  
  .resetpassword__text {

    margin-top: -$apx-m-10;
    font-size: $apx-m-14;
    line-height: $apx-m-16;
    padding: $apx-m-6 $apx-m-12;
    border-radius: $apx-m-6;
    margin-bottom: $apx-m-13;
  
  }
  
  .twoinputs {
  
    .textfield {
  
      & + .textfield { margin-left: 3.5%; }
      
      &-wrapper__field { width: 100%; }
  
    }
    
  }

}