@import '../../../styles/apx.scss';

.container__company {

  display: flex;
  flex-direction: column;
  padding-left: unset;
  padding-right: unset;

  svg {

    cursor: pointer;
    min-height: $apx-44;
    margin-top: -$apx-11;

  }

}

.company {

  &__info__edit-button,
  &__employee__edit-button {

    width: $apx-36;
    height: $apx-36;
  
  }

  &-head {

    color: #676767 !important;
    font-weight: 600 !important;
    font-size: $apx-25 !important;
    line-height: $apx-23 !important;

    &__periodtext {
          
      color: #757575;
      font-weight: 600;
      font-size: $apx-16;
      line-height: $apx-20;
      margin-top: $apx-8;
      margin-right: $apx-12;
      margin-left: $apx-12;
    
    }

    &__typeprice {
    
      font-family: 'Roboto';
      width: $apx-185;
      height: $apx-80;
      padding-top: $apx-28;
      color: #8E8E8E !important;
      font-weight: 500 !important;
      font-size: $apx-20 !important;
      line-height: $apx-23 !important;
      margin-right: -$apx-26 !important;
      text-align: center !important;
      white-space: nowrap !important;
      border-left: $apx-1 solid #F4F4F4;

      &__a { 
        
        margin-left: $apx-115; 
        padding-left: $apx-12; 
      
      }

      &::before {

        top: 0;
        left: -1px;
        content: "";
        position: absolute;
        width: $apx-2;
        height: $apx-750;
        background: #F4F4F4;

      }
    
    }

  }

  &-header {

    display: flex;
    text-align: center;
    align-items: center;
    height: $apx-40;
    padding: $apx-10;
    margin: $apx-20 $apx-20;

    &__button {
    
      width: $apx-44;
      height: $apx-44;
      margin-top: -$apx-10;
    
    }
    
  }

  &__pagename {

      color: #505050;
      font-size: $apx-36;
      line-height: $apx-33;
      margin-top: -$apx-10;
      margin-left: $apx-33;
      margin-right: $apx-46;
      font-weight: 600 !important;

  }

  &-columns {

    display: flex;
    overflow-x: auto;
    flex-direction: row;
    padding: $apx-10 $apx-30;
    flex-grow: 1;
    max-height: 100%;
    overflow-y: hidden;

    &__info {

      display: flex;
      flex-direction: column !important;

      .company-columns__common { margin-left: unset !important; }
      & + .company-columns__common { margin-left: $apx-22; }

    }

    &__common {

      & + .company-columns__common { margin-left: $apx-22; }

      border-radius: $apx-15;
      border: 1px solid #F4F4F4;

      &__employees__header {

        margin-top: $apx-6;
        padding-top: $apx-12 !important;
        border-left: $apx-2 solid #F4F4F4 !important;
        border-right: $apx-2 solid #F4F4F4 !important;

      }

      &__header {

        max-height: $apx-80;
        padding: 0.9vw 1.56249vw 0.8vw;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F4F4F4;

         svg {

          width: 100%;
          margin-top: 0;
          margin-right: -$apx-12;
          width: $apx-48;

        }

      }

      &__body {

        padding: $apx-10 $apx-25 0;
        text-align: center;
        align-items: start;
        flex-direction: column;
        justify-content: flex-start;

        p, a {

          margin: $apx-1 $apx-15 $apx-1 0;
          font-size: $apx-20 !important;
          line-height: $apx-22 !important;

        }

      }

    }

    &__information {

      width: $apx-710;
      height: $apx-360;

    }

    &__analitycs {

      width: $apx-710;
      height: $apx-365;
      margin-top: $apx-22;

    }

    &__employees {

      height: $apx-750;
      min-width: $apx-715;
      border-left: unset;
      border-right: unset;

      svg { margin-right: -$apx-12; }

      &__box {

        display: flex;
        width: calc( 100% - 5px);
        padding: $apx-27 0 $apx-8 $apx-27;

        &_one {

          width: 100%;

        }

        &_two{

          width: 40%;

        }

        &_three {

          width: 60%;

        }

        &_four {

          width: 60%;

        }

      }

      .table__row {

        &__col:nth-child(2) { text-align: center; }
      }

    }

  }

}

.text-anal {

  color: #757575 !important;
  font-weight: 500 !important;
  font-size: $apx-22 !important;
  line-height: $apx-22 !important;
  margin: $apx-15 0 $apx-15 !important;

  &__subtext {

    white-space: nowrap;
    color: #69D167 !important;
    font-weight: 800 !important;
    font-size: $apx-46 !important;
    line-height: $apx-44 !important;
    margin: $apx-15 0 $apx-15 !important;

    &_small { font-size: $apx-48 !important; }

  }

}

.graytext { color: #B4B4B4 !important; }

.anal {

  width: 100%;
  flex-grow: 1;
  margin: $apx-10;

}

.anal-cont {

  width: 100%;
  flex-grow: 1;
  padding: $apx-25;
  text-align: center;

}

.anal-subcont {

  flex-grow: 1;
  width: 100%;

}

.employees-headtext {

  color: #616161 !important;
  font-size: $apx-20 !important;
  line-height: $apx-20 !important;
  font-weight: 600 !important;
  font-family: Roboto !important;

}

.employees-list__box {

  flex-grow: 1;
  overflow-y: scroll;

}

.boxer { width: 100%; }

.col { flex-direction: column !important; }

.rmv_emplooye_btn {

  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  min-width: 17px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transition: 0.3s;

}

.rmv_company_btn {

  margin: 0.41666vw 0 0.83333vw 1.4vw;
  color: red;
  cursor: pointer;
  &:hover { text-decoration: underline; }

}

.table__row {

  &__col { position: relative; }

  &:hover .rmv_emplooye_btn {
    opacity: 1;
  }

}

//мобильный адаптив
@media screen and ( max-width: 800px ) {

  .container__company { max-height: unset; }

  .company-header {

    width: 100%;
    height: unset;
    padding: $apx-m-35 0;
    display: block !important;
    border-bottom: $apx-m-1 solid rgba(220, 220, 220, 0.5);

    &-12, &-13 { display: none !important; }

    .company__pagename {

      font-weight: 600;
      font-size: $apx-m-28;
      line-height: $apx-m-34;
      margin-bottom: $apx-m-20;
      text-align: center;

    }

    .rmv_company_btn  {
    
      left: -$apx-m-10;
      position: relative;
      font-size: $apx-m-15;
      margin-left: $apx-m-18;
      margin-top: $apx-m-5;
      margin-bottom: -$apx-m-20;
      text-align: right;
      padding-right: 2.5vw;
    
    }

  }

  .company-columns {

    max-height: unset;
    max-width: 100vw;
    overflow-x: hidden;
  
    &--mobile-page {
    
      &-11 {
      
        .pricelist { display: none; }
        .company-columns__info { display: flex; }
        .company-columns__employees { display: none; }
      
      }

      &-12 {
      
        .pricelist { display: flex; }
        .company-columns__info { display: none; }
        .company-columns__employees { display: none; }
      
      }

      &-13 {
      
        .pricelist { display: none; }
        .company-columns__info { display: none; }
        .company-columns__employees { display: flex; }
      
      }
    
    }

    .company-columns__common__employees__header {

        margin-top: unset;
        padding-top: unset !important;
        min-height: $apx-m-40 !important;
        max-height: $apx-m-40 !important;
        margin-bottom: $apx-m-30 !important;
        border-top: $apx-m-1 solid rgba(220, 220, 220, 0.5) !important; 
        border-bottom: $apx-m-1 solid rgba(220, 220, 220, 0.5) !important;
    
    }

    .company-columns__common {
    
      height: unset;
      min-height: unset;
      width: $apx-m-365;
      max-width: 100vw;
      overflow-x: hidden;
      padding: $apx-m-20 $apx-m-10;
      border: unset;
      border-bottom: $apx-m-1 solid rgba(220, 220, 220, 0.5);
      margin-left: unset !important;

      &__employees { border-bottom: unset; }
     
      &__header {
        
        border: unset !important;
        margin-bottom: $apx-m-20;

        &__price { height: none; margin-bottom: -$apx-m-35; }
      
      }

      .anal { display: unset; text-align: left;}

      &__body {

        p, a {

          font-weight: 600 !important;
          font-size: 3.733338vw !important;
          line-height: 4.800006vw !important;

        }

      }

    }

    .company-columns__info,
    .company-columns__analitycs {

      a.company-head { margin-bottom: $apx-m-20 !important; display: block; }
      .company-head {
      
        font-weight: 600 !important;
        font-size: $apx-m-14 !important;
        line-height: $apx-m-18 !important;
        margin-bottom: $apx-m-20;

        

        &.graytext { margin-bottom: $apx-m-5; }

        &-info {
        
          margin-bottom: unset;
          font-size: $apx-m-20 !important;
          line-height: $apx-m-24 !important;
        
        }
      
      }

      .company__info__edit-button {
      
        width: $apx-m-30;
        height: $apx-m-30;
      
      }

    }

    .company-columns__analitycs {

      padding-bottom: $apx-m-12;

      &__header {
      
        display: unset !important;

        .company-head {
          
          text-align: left;
          margin-top: -$apx-m-25;

          &__periodtext {
          
            font-size: $apx-m-13;
            line-height: $apx-m-16;
            margin-top: $apx-m-23;
            margin-right: $apx-m-10;
          
          }
        
        }
      
      }

      .anal-cont {

        text-align: left;
        display: block !important;
      
      }
      
      .company-head {

        margin-bottom: $apx-m-6;

        &__top, &__both, &__middle { margin-bottom: $apx-m-22; }

        &__top {

          font-size: $apx-m-41 !important;
          line-height: $apx-m-50 !important;
        
        }

        &__both {
        
          font-size: $apx-m-35 !important;
          line-height: $apx-m-43 !important;
        
        }

        &__middle {
        
          font-size: $apx-m-37 !important;
          line-height: $apx-m-45 !important;
        
        }
          
        &__major {
        
          font-size: $apx-m-19 !important;
          line-height: $apx-m-23 !important;
        
        }

        &__major {
        
          font-size: $apx-m-15 !important;
          line-height: $apx-m-19 !important;
        
        }

      }
  
    }

    .company-head__addpeople {

      margin-top: $apx-m-15;
      font-size: $apx-m-20 !important;
      line-height: $apx-m-24 !important;
    
    }

    .company__employee__edit-button {
    
      width: $apx-m-30;
      height: $apx-m-30;
      margin-top: $apx-m-15;
    
    }

  }

}