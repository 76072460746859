@import '../../../styles/apx.scss';
// @import '../../../styles/tailwind.scss';

.pricelist {

  min-width: $apx-725;
  height: $apx-750;
  display: flex;
  flex-direction: column;
  padding: 0 0 $apx-8;

  &__content {

    flex-grow: 1;
    display: flex;
    align-items: center;
    width: calc( 100% - 5px );
    margin-top: 5px;
    flex-direction: column !important;
    justify-content: flex-start !important;
    overflow-y: scroll;

    &_box { width: 100%; }

    &_head {

      width: $apx-320;
      padding: $apx-25;
      min-height: $apx-65;

      p {

       color: #616161;
       font-weight: 600;
       font-size: $apx-26;
       line-height: $apx-32;
       white-space: nowrap;

      }

    }

    &_list {

      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column !important;

    }

  }

}

@media screen and ( max-width: 800px ) {

  .pricelist {

    height: unset;
    min-width: unset;
    padding: $apx-m-30 $apx-m-20;

    .company-head {
    
      font-weight: 600 !important;
      font-size: $apx-m-20 !important;
      line-height: $apx-m-24 !important;
      margin-bottom: -$apx-m-30;
    
    }
  
    &__content {

      width: 100%;
      overflow-x: unset !important;
      overflow-y: unset !important;
      &::-webkit-scrollbar {

        width: $apx-m-8;
        height: $apx-m-10;

      }
      &::-webkit-scrollbar-track { 
        
        border-radius: $apx-m-15;
        background-color: #FFF !important;
      
      }
      &::-webkit-scrollbar-thumb { border-radius: $apx-m-15; }
  
      &_head {
        
        min-height: unset;
        width: $apx-m-350;
        padding: $apx-m-5;
        margin: $apx-m-25 0;
  
        p {
  
          width: $apx-m-250;
          font-size: $apx-m-18;
          line-height: $apx-m-24;
          margin-bottom: -$apx-m-20;
          white-space: break-spaces;
  
        }
  
      }
  
    }
  
  }

}