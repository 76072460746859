//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';


.sign {

  &-header {

    display: flex;
    text-align: center;
    align-items: center;
    width: $apx-650;
    height: $apx-100;
    padding: $apx-10;
    margin-bottom: $apx-28;

  }

  &__pagename {

      color: #505050;
      font-size: $apx-33;
      line-height: $apx-33;
      margin-top: -$apx-11;
      margin-left: $apx-33;
      margin-right: $apx-46;
      font-weight: 600 !important;

  }

  &-auth__block {
  
    margin: 0 auto;
    margin-top: $apx-163;
    width: $apx-380;
    height: $apx-404;
    border-radius: $apx-18;
    box-shadow: 0 0 0.5vw 0.135vw rgba(50, 50, 50, 0.05);

    &__title {

      width: 100%;
      height: $apx-84;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #F0F0F0;

      p {

        color: #4AB748;
        text-align: center;
        font-weight: 700;
        font-size: $apx-30;
        line-height: $apx-37;

      }

    }

    &__fields {

      padding: $apx-22 $apx-24 0;
      border-bottom: 1px solid #F0F0F0;

      input {
      
        height: $apx-50;
        margin-top: $apx-8;
        border-radius: $apx-6;
        background-color: #FCFCFC;
      
      }

    }

    .error {
      margin-bottom: $apx-16;
    }

    &__button {

      padding: 0 $apx-24;
      height: $apx-100;
      display: flex;
      align-items: center;
      justify-content: center;

      &__item {
      
        margin: 0 auto;
        width: $apx-333 !important;
        height: $apx-50 !important;
        border-radius: $apx-5 !important;
      
      }

    }
  
  }

}

.textfield-wrapper__title {

  font-family: Roboto;
  color: #595959;
  font-weight: 400;
  font-size: $apx-16;
  line-height: $apx-20;

}

@media screen and (max-width: 800px) {

  .sign {
  
    &-auth__block {
    
      width: $apx-m-330;
      height: $apx-m-320;
      margin-top: $apx-m-150;
      border-radius: $apx-m-8;
      box-shadow: 0 0 1vw 0.35vw rgba(50, 50, 50, 0.05);
  
      &__title {

        height: $apx-m-60;
        border-bottom: $apx-m-1 solid #F0F0F0;
  
        p {

          font-weight: 700;
          font-size: $apx-m-22;
          line-height: $apx-m-26;
  
        }
  
      }
  
      &__fields {
  
        padding: $apx-m-15 $apx-m-20;
        border-bottom: $apx-m-1 solid #F0F0F0;
  
        input {
        
          height: $apx-m-42;
          margin-top: $apx-m-8;
          border-radius: $apx-m-6;
        
        }
  
      }
  
      .error { margin-bottom: $apx-m-12; }
  
      &__button {
  
        height: $apx-m-60;
        padding: $apx-m-24;
        margin-top: $apx-m-8;
  
        &__item {

          width: $apx-m-300 !important;
          height: $apx-m-46 !important;
          border-radius: $apx-m-6 !important;
        
        }

        p {
        
          font-size: $apx-m-18 !important;
          line-height: $apx-m-20 !important;
        
        }
  
      }
    
    }
  
  }
  
  .textfield-wrapper__title {

    font-size: $apx-m-16;
    line-height: $apx-m-20;
  
  }

}