
//подключение apx
@import '../../../styles/apx.scss';

.itemboxer {

  cursor: pointer;
  margin-top: $apx-35;

}

.itemtext {

  p {
    
  color: #8D8D8D !important;
  font-size: $apx-20 !important;
  line-height: $apx-20 !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  }

}

.text-center {

  text-align: center !important;
  position: relative;
  left: $apx-5;

}