//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';


.table-employee__mobile {

  &__row {

    width: $apx-m-373;
    margin-left: -$apx-m-15;
    padding: $apx-m-10 $apx-m-20;
    &:nth-child(2n) { background-color: rgba(244, 244, 244, 0.6); }

    p, div {
      
      font-weight: 500;
      color: #8D8D8D;
      font-family: Roboto;
    
    }

    &__names {

      font-size: $apx-m-12;
      line-height: $apx-m-14;
      color: rgba(142, 142, 142, 0.8) !important;
      margin-bottom: $apx-m-4;
    
    }

    &__fcs {
    
      font-size: $apx-m-18;
      line-height: $apx-m-20;
      color: #8D8D8D !important;
      margin-bottom: $apx-m-24;
    
    }

    &__types {
    
      font-weight: 600;
      font-size: $apx-m-12;
      line-height: $apx-m-14;
      color: rgba(142, 142, 142, 0.8) !important;
      margin-bottom: $apx-m-4;
    
    }

    &__values {
    
      font-weight: 500;
      font-size: $apx-m-18;
      line-height: $apx-m-20;
      color: #8D8D8D !important;
    
    }

    &__typevalue {
    
      &__first { width: $apx-m-120; }
      &__second { width: $apx-m-150; }
      &__first { width: $apx-m-80; }
    
    }

  }

}