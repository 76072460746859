//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';


.site {

  user-select: none;
  padding: $apx-30 $apx-28 $apx-102;

  .accordeon { 
    
    margin-bottom: $apx-15; 

    &-children {
    
      padding: $apx-20;
      border-top: unset !important;
      border: $apx-2 solid #E0E0E0;
      border-radius: 0 0 $apx-15 $apx-15;

    }

    &:last-child .accordeon-children { margin-bottom: $apx-40; }
  
  }

  &__accordeon {
  
    width: 100%;

    &-examples {
    
      display: grid;
      row-gap: $apx-15;
      column-gap: $apx-13;
      grid-template-columns: repeat(9, $apx-190);
    
    }

    &-head {

      background: #F0F0F0;
      border: $apx-2 solid #E0E0E0;
      border-radius: $apx-15;
      padding: $apx-20 $apx-20;

      &__arrow {
      
        width: $apx-22;
        height: $apx-16;
        margin-right: $apx-30;
        transition: 0.2s;
      
      }

      svg { margin-right: $apx-25; }

      p {
      
        font-weight: 600;
        color: #676767;
        font-size: $apx-24;
        line-height: $apx-30;
      
      }

      &__button {
      
        width: unset;
        height: unset;
        top: $apx-15;
        right: $apx-15;
        border-radius: $apx-6;
        padding: $apx-10 $apx-22;
        background: #79D877;
        display: none !important;

       .button__icon {

          width: $apx-30;
          height: $apx-30;
        
          svg {

            width: 100%;
            height: 100%;
            max-height: unset;

          }
        
        }

        p {

          color: #FFF;
          font-weight: 700;
          font-size: $apx-18;
          line-height: $apx-21;          
          text-shadow: 0 $apx-1 $apx-5 rgba(0, 0, 0, 0.1);
        
        }
      
      }
    
    }
  
  }

  .accordeon--opened {
  
    .site__accordeon-head {    

      background: #FFF;
      padding: $apx-11 $apx-20;
      border-radius: $apx-15 $apx-15 0 0;
      
      &__arrow {
      
        width: $apx-22;
        height: $apx-16;
        transition: 0.2s;
        transform: rotate(180deg);
      
      }

      &__button { display: flex !important; }

    }
  
  }

  .offertext {
    font-size: $apx-18;
    font-size: $apx-20;
    font-weight: 500;
    color: #484848;
  }

}

.nocontent {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: 600;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4vw;
  color: #e7e7e7;
}

@media screen and (max-width: 800px) {

  .site {

    padding: 0;
    max-height: unset;
    padding-bottom: $apx-m-100 !important;

    .accordeon { 
      
      margin-bottom: 0;
  
      &-children {
      
        padding: $apx-m-20;
        border: $apx-m-2 solid #E0E0E0;
        border-radius: unset;
  
      }
  
      &:last-child .accordeon-children { margin-bottom: 0; }
    
    }
  
    &__accordeon {
  
      &-examples {

        row-gap: $apx-m-20;
        column-gap: $apx-m-20;
        grid-template-columns: repeat(2, $apx-m-160);
      
      }
  
      &-head {

        height: $apx-m-80;
        padding: $apx-m-15;
        border-radius: unset;
        border: $apx-m-2 solid #E0E0E0;
  
        &__arrow {
        
          width: $apx-m-18;
          height: $apx-m-10;
          margin-right: $apx-m-20;
        
        }
  
        svg { margin-right: $apx-m-15; }
  
        p {

          font-size: $apx-m-18;
          line-height: $apx-m-22;
        
        }
  
        &__button {
        
          top: $apx-m-15;
          right: $apx-m-15;
          border-radius: $apx-m-6;
          padding: $apx-m-10 $apx-m-22;
  
         .button__icon {
  
            width: $apx-m-23;
            height: $apx-m-23;
          
          }
  
          p {

            font-size: $apx-m-14;
            line-height: $apx-m-16;          
            text-shadow: 0 $apx-m-1 $apx-m-5 rgba(0, 0, 0, 0.1);
          
          }
        
        }
      
      }
    
    }
  
    .accordeon--opened {
    
      .site__accordeon-head {    
  
        padding: $apx-m-15;
        border-radius: unset;
        
        &__arrow {
        
          width: $apx-m-18;
          height: $apx-m-10;
        
        }
  
      }
    
    }
  
    .offertext {
      font-weight: 600;
      font-size: $apx-m-14;
      line-height: $apx-m-18;
    }
  
  }

  .site__accordeon-reviews {

    padding: 0 !important;
    margin-bottom: $apx-m-500 !important;
  
  }

  .nocontent { font-size: 4.4vw; }

}