//подключение apx
@import '../../../styles/apx.scss';

.popup__review {

  width: 100%;
  height: 100%;
  width: $apx-608;
  padding: $apx-23 $apx-28 $apx-5;

  svg, img {

    cursor: pointer;
    overflow: hidden;
    background-size: cover;
    border-radius: $apx-12; 
    width: $apx-120;
    height: $apx-120;
    margin: $apx-12 0 $apx-24;
    margin-right: $apx-20;

    &:first-child { margin-left: unset; }

  }

  img { object-fit: cover; }

  .images {

    img {

      object-fit: cover;
      width: $apx-120 !important;
      height: $apx-120 !important;

    }

  }

}

.imageload {

  user-select: none;
  cursor: pointer;
  overflow: hidden;
  width: $apx-120;
  height: $apx-120;
  border-radius: $apx-8;
  border: $apx-5 solid #E6E6E6;
  margin: 0.624996vw 0 1.249992vw;
  margin-right: 1.04166vw;

  &__image {
  
    width: $apx-42 !important;
    height: $apx-42 !important;
    margin: unset !important;
  
  }

  &__progress {

    width: 0%;
    height: 100%;
    background: linear-gradient(45deg, #8eea8e, #48eb48);

  }

}

@media screen and (max-width: 800px) {

  .popup__review {

    width: 100%;
    height: 100%;
    width: $apx-m-350;
    padding: $apx-m-10 $apx-m-15;
  
    svg, img {
  
      cursor: default;
      width: $apx-m-96;
      height: $apx-m-88;
      border-radius: $apx-m-8; 
      margin-right: $apx-m-15;
      margin: $apx-m-10 0 $apx-15;
  
      // &:first-child { margin-left: unset; }
  
    }
  
    .images {

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
  
      img {
  
        min-width: $apx-m-96 !important;
        min-height: $apx-m-88 !important;
        margin-right: $apx-m-15;
  
      }
  
    }
  
  }
  
  .imageload {

    cursor: default;
    border-radius: $apx-m-8;
    width: $apx-m-96 !important;
    height: $apx-m-88 !important;
    border: $apx-m-3 solid #E6E6E6;
    margin-right: $apx-m-15 !important;
    margin: $apx-m-10 0 $apx-15;
  
    &__image {
    
      width: $apx-m-42 !important;
      height: $apx-m-42 !important;
    
    }
  
    &__progress {
  
      width: 0%;
      height: 100%;
      background: linear-gradient(45deg, #8eea8e, #48eb48);
  
    }
  
  }

}