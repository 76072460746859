//подключение apx
@import '../../../styles/apx.scss';

.images {

  &__image {

    width: $apx-188;
    height: $apx-188;
    overflow: hidden;
    border-radius: $apx-10;

    img { object-fit: cover !important; }

    &__blur {

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #242424bd;
      transition: 0.3s;
      opacity: 0;
      
      &__trash {
      
        width: $apx-35;
        height: $apx-40;
        top: $apx-75;
        left: $apx-75;
      
      }

    }

    &:hover .images__image__blur { opacity: 1; }

    img {
  
      width: 100%;
      height: 100%;
      background-size: cover;
    
    }

  }

  // .images__image__blur {

  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #242424bd;
  //   transition: 0.3s;
  //   opacity: 0;
    
  //   &__trash {
    
  //     width: $apx-35;
  //     height: $apx-40;
  //     top: $apx-75;
  //     left: $apx-75;
    
  //   }

  // }

}

@media screen and (max-width: 800px) {

  .images {

    &__image {
  
      width: $apx-m-160;
      height: $apx-m-160;
      border-radius: $apx-m-8;
  
      &__blur {
  
        opacity: 1;
        left: unset;
        right: 0;
        width: $apx-m-60;
        height: $apx-m-50;
        border-bottom-left-radius: $apx-m-8;
        
        &__trash {
        
          width: $apx-m-25;
          height: $apx-m-30;
        
        }
  
      }
  
    }

  }

}