@import '../../../styles/apx.scss';

.menu-mobile {

  z-index: 10;
  top: unset;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $apx-m-75;
  background: #FFF;
  border: $apx-m-1 solid #F3F3F3;
  border-top-left-radius: $apx-m-16;
  border-top-right-radius: $apx-m-16;
  box-shadow: 0px -2px 8px 0px #00000012;

  &__item {

    min-width: $apx-m-75;
    margin: 0 $apx-m-8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
  
    &__icon {

      &__container {

        width: $apx-m-30;
        height: $apx-m-30;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $apx-m-5;

      }

      &__second { transform: scale(0.9); }
    
      &__one {
      
        width: $apx-m-29;
        height: $apx-m-27;
      
      }

      &__two {
      
        width: $apx-m-23;
        height: $apx-m-23;
      
      }

      &__three {
      
        width: $apx-m-26;
        height: $apx-m-21;
      
      }

      &__four {
      
        width: $apx-m-23;
        height: $apx-m-24;
      
      }
    
    }

    &__text {

      user-select: none;
      text-align: center;
      font-family: Roboto;
      font-weight: 500;
      font-size: $apx-m-12;
      line-height: $apx-m-14;
      color: rgb(128, 128, 128);

      &__orange_main { color: rgb(236, 185, 0) !important; }
      &__orange { color: rgb(235, 140, 55) !important; }
      &__blue_main { color: rgb(100, 160, 230) !important; }
      &__blue { color: rgb(100, 160, 232) !important; }
      &__green_main { color: rgb(100, 200, 150) !important; }
      &__green { color: rgb(60, 186, 60) !important; }

    }

    &__liner {
    
      width: $apx-m-1;
      height: $apx-m-42;
      background: #EBEBEB;
      transform: rotate(180deg) !important;
    
    }
  
  }

  &__thumb {
  
    top: 0;
    left: 0;
    width: $apx-m-88;
    height: $apx-m-8;
    border-radius: $apx-m-60;
    transition: 0.5s !important;
    background: rgb(128, 128, 128);

    &-1 {
    
      // left: $apx-m-64; // вернуть, когда будет страница материалов
      left: 25.066688vw;
      transition: 0.5s !important;
      background: rgb(100, 200, 150);
    
    }

    &-2 {
    
      // left: $apx-m-155; // вернуть, когда будет страница материалов
      left: 49.333385vw;
      transition: 0.5s !important;
      background: rgb(100, 160, 230);
    
    }

    &-3 {
    
      left: $apx-m-248;
      transition: 0.5s !important;
      background: rgb(236, 185, 0);
    
    }

    &-11 {
    
      left: $apx-m-92;
      background: rgb(235, 140, 55);
    
    }

    &-12 {
    
      left: $apx-m-183;
      background: rgb(100, 160, 232);
    
    }

    &-13 {
    
      left: $apx-m-278;
      background: rgb(60, 186, 60);
    
    }
  
  }

  // .ml-69-m { margin-left: $apx-m-69; } // вернуть, когда будет страница материалов
  .ml-69-m { margin-left: 26.400023vw; } 

}