//подключение apx
@import '../../../styles/apx.scss';

.company-columns__info {  

  span {

    color: #AFAFAF;
    font-weight: 400;
    font-size: $apx-16;
    line-height: $apx-21;

  }

}

.divider {

  &__left, &__right {

    width: 100%;
    height: 100%;
    width: $apx-608;
    padding: $apx-23 $apx-28 $apx-5;
    border: 0.5px solid #F0F0F0;

    .popup-text {

      color: #676767;
      font-weight: 600;
      font-size: $apx-21;
      line-height: $apx-21;
      padding: $apx-5 0 $apx-30;

    }

  }

}

.popup__button__generate-pass {

  padding: 0 $apx-15;
  margin-top: $apx-22;
  margin-left: $apx-18;
  height: $apx-53 !important;
  position: relative;

  &__employee { top: $apx-5 !important; }

  &__company { top: $apx-5 !important; }

}

.textfield-siteurl {

  &__fields {

    &__site {

      font-size: 21px;
      padding-left: 24px;
      white-space: nowrap;
      color: #989898;

    }

    &__input {

      max-width: 39%;
      padding-left: 0;
      margin-left: $apx-5;

    }

  }

}

.company_info {

  .twoinputs {

    .textfield:first-child { width: $apx-420; }

  }

}

@media screen and (max-width: 800px) {

  .divider {

    &__left, &__right {
  
      width: 100%;
      height: 100%;
      border: unset;
      padding: 0 $apx-m-14;
  
      .popup-text {

        font-size: $apx-m-18;
        line-height: $apx-m-22;
        padding: $apx-5 0 $apx-30;
        margin: $apx-m-20 0;
  
      }
  
    }
  
  }
  
  .popup__button__generate-pass__employee,
  .popup__button__generate-pass__company {
  
    right: 0;
    margin: 0 auto;
    padding: 0 $apx-15;
    top: $apx-m-20 !important;
    height: $apx-m-42 !important;
    width: $apx-m-140 !important;
    position: absolute;
  
  }
  
  .company-columns__info {  

    span {
  
      color: #AFAFAF;
      font-weight: 400;
      font-size: $apx-m-16;
      line-height: $apx-m-21;
  
    }
  
  }
  
  .textfield-siteurl {
  
    &__fields {
  
      &__site {
  
        font-size: $apx-m-21;
        line-height: $apx-m-24;
        white-space: nowrap;
        color: #989898;
  
      }
  
      &__input {
  
        max-width: 52%;
        padding-left: 0;
  
      }
  
    }
  
  }
  
  .company_info {

    display: unset !important;
  
    .twoinputs {
  
      .textfield:first-child { width: $apx-420; }
  
    }
  
  }

}

@media screen and (max-width: 800px) {

  .textfield-siteurl {
  
    &__fields {
  
      &__site {
  
        padding-left: 0;
        font-size: $apx-m-18;
        line-height: $apx-m-20;
        white-space: nowrap;
        color: #989898;
  
      }
  
      &__input {
  
        max-width: 52%;
        margin-left: $apx-m-2;
  
      }
  
    }
  
  }

  .company_info {
  
    .twoinputs {
  
      .textfield:first-child { width: $apx-m-300; }
  
    }
  
  }

}