.overflow-x { overflow-x: scroll; overflow-y: hidden; }

.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }

.flex { display: flex; }
.flex-row { flex-direction: row; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-col { flex-direction: column; }
.flex-col-reverse { flex-direction: column-reverse; }
.flex-1 { flex: 1 1; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-evenly { justify-content: space-evenly; }
.justify-end { justify-content: flex-end; }
.items-center { align-items: center; }
.items-end { align-items: flex-end; }

.text-center { text-align: center !important; }
.text-right { text-align: right !important; }
.font-light { font-weight: 300; }
.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.font-extrabold { font-weight: 800; }
.font-black { font-weight: 900; }

.pointer-events-none { pointer-events: none; }