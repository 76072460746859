//подключение apx
@import '../../../styles/apx.scss';

.container {

  display: flex;
  flex-direction: column !important;
  max-width: unset !important;
  max-height: 100vh;
  
  &__content {
    
    padding: $apx-14 $apx-26 0;
    max-height: 94vh;
  
  }

  &__companies { padding-top: $apx-28; }

  &__materials, 
  &__companies {

    display: flex;
    flex-direction: column;

    p { color: #505050; }

  }

}

@media screen and ( max-width: 800px ) {

  .container {
    
    max-width: 100vw !important;
    overflow-x: hidden !important;
    
    &__content {

      padding-bottom: 200px;
      max-height: unset;

    }
  
  }

}