@import '../../../styles/apx.scss';

.period_bar {

  margin-right: -$apx-14;

  .datepicker { margin-left: $apx-10; }
  
  .button {

    min-width: unset;
    white-space: nowrap;
    padding: $apx-7 $apx-16 !important;
    border-radius: $apx-4;

    & + .button { margin-left: $apx-10; }
    
    p {

      position: relative;
      font-family: Montserrat;
      font-size: $apx-16;
      line-height: $apx-20;

    }

    &--unactive {
            
      p {

        font-weight: 500 !important;
        color: #959595 !important;

      }

      background: #F6F6F6;
      border: 1px solid #E9E9E9;

    }

    &--active {
      
      p {

        font-weight: 700 !important;
        color: #fff !important;

      }

      background: #69D167;      
      border: 1px solid #69D167;

    }

  }

}

@media screen and ( max-width: 800px ) {

  .period_bar {

    margin-right: -$apx-m-3;
    margin-top: $apx-m-7;
  
    .datepicker { margin-left: $apx-m-10; }
    
    .button {
  
      border-radius: $apx-m-3;
      padding: $apx-m-15 $apx-m-12 !important;
  
      & + .button { margin-left: $apx-m-5; }
      
      p {

        font-size: $apx-m-12;
        line-height: $apx-m-14;
  
      }
  
      &--unactive { border: 2px solid #E9E9E9; }

      &--active { border: 1px solid #69D167; }
  
    }
  
  }

}