//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.table {

  width: 100%;
  min-height: 0;
  max-height: $apx-380;
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column;

  &__rows {

    min-height: 0;
    overflow-y: scroll;

  }
  
  &__row {
    
    color: #616161;
    font-family: Roboto;
    font-weight: 500;
    height: $apx-39;
    font-size: $apx-20;
    line-height: $apx-23;
    transition: 0.75s;
    cursor: pointer;

    &:nth-child(2n) { background-color: rgba( 120, 216, 120, 0.06 ); }

    &--head {
      
      width: calc( 100% - 6px );
      color: #9E9E9E;
      font-size: $apx-22;
      line-height: $apx-26;
      padding: $apx-28 0 $apx-28;
      user-select: none;

    }

    &--selected,
    &--selected:hover {
      
      background-color: #77d870a3 !important;

    }

    &:not( .table__row--head ):hover {
      background-color: rgba( 120, 216, 120, 0.65 );
    }

    &__col:first-child { padding-left: $apx-16; }

  }

}

.employee-table {

  border: $apx-2 solid #F4F4F4;
  border-bottom-left-radius: $apx-15;
  border-bottom-right-radius: $apx-15;

}