//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

svg {

  width: 100%;
  height: 100%;

}

.companies {

  display: grid;
  grid-template-rows: 28px 1fr;

  &__head {
  
    color: #414141;
    font-weight: 600;
    font-size: $apx-34;
    line-height: $apx-41;
  
  }

  &__add-button {
  
    &__container {

      margin: -$apx-4 $apx-39 0 $apx-16;

      button { padding: 0.38083vw 1.14583vw; }

    }

  }

  &__search {
  
    &__container {
    
      width: $apx-320;
      height: $apx-44;
      margin-top: -$apx-4;
      padding: 0 $apx-20 0 $apx-20;
      border-radius: $apx-22;
      border: $apx-1 solid #DCDCDC;
      background-color: #FAFAFA;

      .textfield {

        input, input::placeholder {

          border: unset !important;
          outline: unset !important;
          background: unset !important;
          padding: 0 $apx-12 !important;
          margin-top: $apx-20 !important;
          color: #AAAAAA;
          font-family: Roboto;
          font-weight: 500;
          font-size: $apx-19;
          line-height: $apx-23;
  
        }
        
  
      }

    }

    &__icon {
    
      min-width: $apx-21;
      min-height: $apx-21;
    
    }

  }

  .table {

    &__row {
        
      &__col:nth-child(5),
      &__col:nth-child(6),
      &__col:nth-child(7) { text-align: center; }

    }

  }

}

@media screen and ( max-width: 800px ) {

  .companies {

    display: flex;
    
    &__header {
  
      width: 100%;
      height: $apx-m-100;
      padding: $apx-m-18 $apx-m-8 0 $apx-m-8;
      display: block !important;

      &__headmobile { justify-content: space-between; }
  
    }

    &__head {
    
      font-size: $apx-m-20;
      line-height: $apx-m-24;
      margin-left: $apx-m-4;
    
    }

    &__add-button {

      &__container { padding: unset; }
      
      &__button {
    
        width: $apx-m-130;
        height: $apx-m-35;
        margin-top: -$apx-m-13;
        margin-right: -$apx-m-8;
        border-radius: $apx-m-5;

        .button__icon { display: none; }

      }
    
    }

    &__search {

      &__container {
        
        width: $apx-m-350;
        height: $apx-m-40;
        border-radius: $apx-m-10;
        margin: $apx-m-14 0 $apx-m-18;
      
      }
    
      &__textfield {

        width: $apx-m-350;
        height: $apx-m-30;
        margin-top: $apx-m-10;

        input, input::placeholder {

          font-weight: 600 !important;
          font-size: $apx-m-14 !important;
          line-height: $apx-m-18 !important;
          padding: $apx-m-8 $apx-m-15 !important;
  
        }    
  
      }

      &__icon {
        
        min-width: $apx-m-20;
        min-height: $apx-m-20;
        max-width: $apx-m-20;
        max-height: $apx-m-20;
        margin-right: $apx-m-10;
        margin: $apx-m-3 $apx-m-10 0 $apx-m-5;
      
      }
    
    }

    &__cardlist { padding-bottom: $apx-m-100; }
 
  }

}