//подключение apx
@import '../../../styles/apx.scss';

.popup {

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: none;
  user-select: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.5);

  &__body {

    border-radius: $apx-18;
    background-color: #fff;
    overflow: hidden;

    &__header {

      width: 100%;
      height: $apx-78;
      padding: $apx-23;
      padding-bottom: $apx-28;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F0F0F0;

      p {

        color: #505050;
        font-weight: 600;
        padding: $apx-24;
        margin-top: $apx-4;
        font-size: $apx-24;
        line-height: $apx-24;

      }

      svg {

        top: $apx-9;
        width: $apx-31;
        height: $apx-31;
        position: relative;

      }

    }

    &__content {

      padding: $apx-23;

    }

  }

  .closing-img {

    svg {

      width: $apx-26;

    }

  }

  .textfield-wrapper__title {

    color: #595959;
    font-size: $apx-16;
    line-height: $apx-18;
    margin-bottom: $apx-6;

  }

  .textfield-wrapper__field {

    outline: none;
    min-height: $apx-50;

  }

}

@media screen and (max-width: 800px) {
  
  .popup {

    padding: 0;
    overflow-y: scroll;
    height: 100% !important;

    &__body {

      width: 93%;
      margin: $apx-m-15 auto;
      border-radius: $apx-m-18;

      &__header {

        height: $apx-m-60;
        padding: 0 $apx-m-15;
        border-bottom: $apx-m-1 solid #F0F0F0;

        p {

          margin-top: 0;
          width: 100%;
          font-weight: 600;
          padding: $apx-m-6;
          font-size: $apx-m-16;
          line-height: $apx-m-20;
          text-align: center !important;

        }

        svg {

          top: -$apx-m-5;
          width: $apx-m-23 !important;
          height: $apx-m-23 !important;

        }

      }

      &__content { padding: $apx-m-18 $apx-m-14; }

    }

    .closing-img {

      svg {

        width: $apx-m-18 !important;
        height: $apx-m-18 !important;

      }

    }

    .textfield-wrapper__title {

      font-size: $apx-m-14;
      line-height: $apx-m-16;
      margin-bottom: $apx-m-8;

      span {
      
        font-size: $apx-m-14;
        line-height: $apx-m-16;
      
      }

    }

    .textfield-wrapper__field {

      background: #FCFCFC;
      min-height: $apx-m-42;
      border-radius: $apx-m-6;
      border: $apx-m-1 solid #E6E6E6;

    }

  }

}