//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.button {

  width: 100%;
  min-width: $apx-155;
  border-radius: $apx-5;
  background: #79D877;

  p {

    display: flex;
    color: #fff;
    font-weight: 500;
    font-size: $apx-18;
    line-height: $apx-24;
    text-shadow: 0 0 $apx-1 rgba(128, 128, 128, 0.8);
 
  }

  &__icon {

    left: -$apx-6;
    margin-right: $apx-8;

    svg, img { max-height: $apx-18; }
  
  }

  &--yellow {

    background-color: #F5C92A;

  }

  &--green {

    background-color: #79D877;

  }

  &--red {

    background-color: #F8A2A2;

  }

}

@media screen and ( max-width: 800px ) {

  .button {

    width: 100%;
    min-width: $apx-m-100;
    border-radius: $apx-m-5;
  
    p {

      margin: unset;
      font-size: $apx-m-14;
      line-height: $apx-m-18;
      text-shadow: 0 0 $apx-m-1 rgba(128, 128, 128, 0.8);
   
    }
  
    &__icon {
  
      max-width: 50px;
      left: -$apx-m-6;
      margin-right: $apx-m-8;
  
      svg, img { 
        
        max-width: 50px;
        max-height: $apx-m-10; 
      
      }
    
    }
  
  }

}