@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.table-card {

  margin: 0 auto;
  margin-top: $apx-m-21;
  width: $apx-m-350;
  border-radius: $apx-m-11;
  box-shadow: 0 $apx-m-1 $apx-m-12 $apx-m-2 rgba(0, 0, 0, 0.1);
  background: #FFF;

  &__header {
  
    min-height: $apx-m-36;
    padding: $apx-m-8 $apx-m-14 $apx-m-8 $apx-m-8;
    border-bottom: $apx-m-1 solid #F5F5F5;

    &__liner {
    
      margin-top: $apx-m-8;
      min-height: $apx-m-1;
      min-width: $apx-m-22;
      max-height: $apx-m-1;
      max-width: $apx-m-22;
      margin-right: $apx-m-8;
      transform: rotate(90deg);
      background: rgb(230, 230, 230);
    
    }

    .icon-geo {
    
      min-width: $apx-m-15;
      min-height: $apx-m-18;
      max-width: $apx-m-15;
      max-height: $apx-m-18;
      margin-right: $apx-m-4;
    
    }

    &__city {
    
      width: $apx-m-50;
      margin-top: 1px;
      margin-right: $apx-m-6;
      color: #32BC38 !important;
      font-weight: 500;
      font-size: $apx-m-13;
      line-height: $apx-m-16;
      font-family: Roboto;
      word-wrap: break-word;
      white-space: break-spaces;
    
    }

    &__date {
 
      margin-top: $apx-m-2;
      margin-right: $apx-m-2;
      color: rgb(196, 196, 196) !important;
      font-weight: 500;
      font-size: $apx-m-13;
      line-height: $apx-m-16;
      font-family: Roboto;
    
    }

    .icon-measures {
    
      min-width: $apx-m-18;
      min-height: $apx-m-17;
      max-width: $apx-m-18;
      max-height: $apx-m-17;
      margin-left: -$apx-m-4;
      margin-right: $apx-m-6;
    
    }

    &__measures {

      font-weight: 800;
      margin-top: $apx-m-3;
      margin-right: $apx-m-6;
      font-size: $apx-m-12;
      line-height: $apx-m-14;
      font-family: Roboto;
      color: #848484 !important;
    
    }

    .icon-orders {
    
      min-width: $apx-m-19;
      min-height: $apx-m-17;
      max-width: $apx-m-19;
      max-height: $apx-m-17;
      margin-left: -$apx-m-4;
      margin-right: $apx-m-6;

    }

    &__orders {

      font-weight: 800;
      margin-top: $apx-m-3;
      margin-right: $apx-m-6;
      font-size: $apx-m-12;
      line-height: $apx-m-14;
      font-family: Roboto;
      color: #848484 !important;
      
    }

    .icon-people {
    
      min-width: $apx-m-19;
      min-height: $apx-m-19;
      max-width: $apx-m-19;
      max-height: $apx-m-19;
      margin-left: -$apx-m-10;
      margin-right: $apx-m-4;
    
    }

    &__people {
    
      font-weight: 800;
      margin-top: $apx-m-3;
      font-size: $apx-m-12;
      line-height: $apx-m-14;
      font-family: Roboto;
      color: #848484 !important;
    
    }
  
  }

  &__company {
    
    font-weight: 500;
    font-size: $apx-m-20;
    line-height: $apx-m-23;
    color: rgb(96, 96, 96) !important;
    margin: $apx-m-8 $apx-m-14 $apx-m-4;
  
  }

  &__allnames {
  
    font-weight: 500;
    font-size: $apx-m-15;
    line-height: $apx-m-18;
    color: rgb(156, 156, 156) !important;
    margin-left: $apx-m-14;
    margin-bottom: $apx-m-10;
  
  }

  &__footer {
  
    height: $apx-m-40;
    border-top: $apx-m-1 solid #F5F5F5;

    &__liner {
    
      margin-top: $apx-m-19;
      min-height: $apx-m-1;
      min-width: $apx-m-40;
      max-height: $apx-m-1;
      max-width: $apx-m-40;
      margin-left: -$apx-m-8;
      margin-right: $apx-m-6;
      transform: rotate(90deg);
      background: rgb(230, 230, 230);
    
    }

    .icon-phone {
    
      width: $apx-m-20;
      height: $apx-m-20;
      margin: $apx-m-9 0 0 $apx-m-13;
    
    }

    &__phone {
    
      font-family: Roboto;
      font-weight: 500;
      font-size: $apx-m-14;
      line-height: $apx-m-16;
      color: #338E8E !important;
      margin-top: $apx-m-12;
    
    }

    &__button {
    
      width: $apx-m-155;
      height: $apx-m-40;
      top: 0;
      right: 0;
      position: absolute;
      background: #94DCA0;
      border-radius: $apx-m-10 0 $apx-m-10;

      p { font-size: 4.4vw; }
    
    }
  
  }

}