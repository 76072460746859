//подключение apx
@import '../../../styles/apx.scss';

header {

  width: 100%;
  flex-grow: 1;
  min-height: $apx-55;
  max-height: $apx-55;
  background-color: #F9F9F9;
  border-bottom: $apx-2 solid #ececec;

}

.header {

  &__panel {

    width: 30%;
    height: 100%;

  }
  &__tabs {
    
    width: 60%;
    height: 100%;

  }
  &__user {
      
    width: 30%;
    height: 100%;

  }

    &__tabs__tab {
      
      color: #616161;
      padding: $apx-5;
      min-height: $apx-56;
      margin-top: $apx-2;
      padding-top: $apx-5;
      padding-left: $apx-36;
      padding-right: $apx-42;
      border: $apx-2 solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $apx-12 $apx-12 0 0;

      &__icon {

        margin-right: $apx-16;
        min-width: 1.2vw;
        min-height: 1.4vw;

        svg {

          max-height: $apx-28;

        }

      }

      &__text {

        margin-top: -$apx-3;
        font-size: $apx-18;
        line-height: $apx-20;

      }

      &--active {

        background-color: #FFF;
        border: $apx-2 solid #ececec;
        border-bottom: unset;

      }
    }

}

.admin-panel__div_name, h1 {

  color: #616161;
  font-weight: 500 !important;
  font-size: $apx-22 !important;
  line-height: $apx-24 !important;
  padding-left: $apx-28 !important;

}

@media screen and ( max-width: 800px ) {

 header { display: none !important; }

}