//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.togglechecker {

  margin: $apx-8 0 $apx-16;

  &__container { 

    cursor: pointer;
    user-select: none; 

  }

  &__title {

    color: #434343;
    font-weight: 400;
    font-size: $apx-20;
    margin-bottom: $apx-8;
    font-family: Roboto;

  }

  &__text {

    flex-grow: 1;
    color: #434343;
    font-weight: 400;
    font-size: $apx-22;
    margin-left: $apx-20;
    font-family: Roboto;

  }

  .flex-row-reverse .togglechecker__text { margin-left: unset; }

  &__anchor {

    width: $apx-66;
    height: $apx-32;
    border-radius: $apx-16;
    background-color: #CBCBCB;
    position: relative;
    transition: 0.3s;

    &__dot {

      width: $apx-22;
      height: $apx-22;
      border-radius: $apx-50;
      background-color: #D6D6D6;
      border: $apx-4 solid #F4F4F4;
      transition: 0.3s;
      position: absolute;
      left: $apx-4;
      top: $apx-5;

    }

  }

  &--checked {

    .togglechecker__container {

      .togglechecker__anchor {

        background-color: #7ABB5C;

        &__dot {

          border-color: #fff;
          background-color: #B2E899;
          left: $apx-64 - $apx-26;

        }

      }

    }

  }

}

@media screen and ( max-width: 800px ) {

  
  .togglechecker {
    
    margin: 0 auto;
    width: $apx-m-210;

    &__anchor {
      
      width: $apx-m-44;
      height: $apx-m-22;
      border-radius: $apx-m-16;
    
      &__dot {

        top: $apx-m-4;
        left: $apx-m-5;      
        width: $apx-m-14;
        height: $apx-m-14;
      
      }

    }

    &--checked {

      .togglechecker__container {
  
        .togglechecker__anchor {
  
          &__dot { left: $apx-m-24; }
      
        }
      
      }
      
    }

    &__text {

      font-size: $apx-m-15;
      margin-left: $apx-m-18;
  
    }
  
  }

}