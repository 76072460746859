@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.review {

  width: 100%;
  margin-bottom: $apx-30;
  border-radius: $apx-12;
  padding: $apx-20 $apx-30;
  box-shadow: 0 $apx-1 $apx-10 rgba(0, 0, 0, 0.15);

  &:last-child { margin-bottom: unset; }

  p { color: #424242; }

  &__client {

    flex-grow: 1;
  
    &__name {
    
      font-weight: 700;
      font-size: $apx-25;
      line-height: $apx-23;

    }

    &__text {

      font-weight: 500;
      font-size: $apx-19;
      line-height: $apx-24;
      margin-top: $apx-20;
    
    }
  
  }

  &__images {
  
    margin-left: $apx-35;

    &__image {
    
      width: $apx-118;
      height: $apx-118;
      margin-left: $apx-20;
      border-radius: $apx-8;
      overflow: hidden;

      img {
        
        width: 100%;
        height: 100%;
        object-fit: cover;
      
      }
    
    }

    &__video {

      position: relative;
      cursor: pointer;
      overflow: hidden;
    
      svg {
        position: absolute;
        z-index: 2;
        top: 0; bottom: 0;
        left: $apx-4; right: 0;
        margin: auto;
        min-width: $apx-54;
        height: $apx-54;
      }
    
      &__darker {
    
        position: absolute;
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, 0.45);
    
      }

    }
  
  }

  &__buttons {

    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: $apx-366;
  
    div p {
    
      color: #FFF;
      font-weight: 500;
      font-size: $apx-18;
      line-height: $apx-21;
      text-align: center;
    
    }

    div {
    
      width: $apx-200;
      height: $apx-35;    
      padding-top: $apx-5;
    
    }

    &__grant {
    
      left: -$apx-1;
      background-color: #A1DBA1;
      border-radius: 0 0 0 $apx-15;
    
    }

    &__denay {
    
      right: -$apx-1;
      background-color: #BEBEBE;
      border-radius: 0 0 $apx-15 0;
    
    }

    &__delete {
    
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: $apx-250;
      height: $apx-35;
      padding-top: $apx-5;
      background-color: #DF9A9A;
      border-radius: 0 0 $apx-15 $apx-15;
      transition: 0.5s;
      opacity: 0;

      &:hover { background: #ff4f4f; }

      p {
    
        color: #FFF;
        font-weight: 500;
        font-size: $apx-18;
        line-height: $apx-21;
        text-align: center;
      
      }
    
    }
  
  } 

  &:hover {
  
    .review__buttons__delete { opacity: 1; }
  
  }

}

@media screen and (max-width: 800px) {

  .review {

    margin-bottom: $apx-m-20;
    border-radius: $apx-m-15;
    padding: $apx-m-25 $apx-m-20;
    box-shadow: 0 $apx-m-1 $apx-m-10 rgba(0, 0, 0, 0.15);
    flex-direction: column !important;
  
    &__client {
  
      flex-grow: 1;
    
      &__name {

        font-size: $apx-m-15;
        line-height: $apx-m-15;
  
      }
  
      &__text {

        margin: $apx-m-10 0 $apx-m-20;
        font-size: $apx-m-13 !important;
        line-height: $apx-m-16 !important;
      
      }
    
    }
  
    &__images {
    
      margin-left: unset;
      margin-bottom: $apx-m-50;
      flex-direction: row !important;
  
      &__image {
      
        width: $apx-m-96;
        height: $apx-m-96;
        margin-left: unset;
        margin-right: $apx-m-12;
        border-radius: $apx-m-10;
      
      }
  
      &__video {

        cursor: default;
      
        svg {
          left: 0;
          min-width: $apx-m-36;
          height: $apx-m-36;
        }
  
      }
    
    }
  
    &__buttons {
  
      bottom: 0;
      top: unset;
      width: 100%;
    
      div p {
      
        font-size: $apx-m-14;
        line-height: $apx-m-16;
      
      }
  
      div {
      
        width: 50%;
        height: $apx-m-44;    
        padding-top: $apx-5;
      
      }
  
      &__grant {

        left: 0;
        p { top: $apx-m-13; }
        border-radius: 0 0 0 $apx-m-15;
      
      }
  
      &__denay {
        
        right: 0;
        p { top: $apx-m-13; }
        background-color: #FB9A9A;
        border-radius: 0 0 $apx-m-15 0;

      }
  
      &__delete {
      
        opacity: 1;
        top: unset;
        bottom: 0;
        width: 100%;
        height: $apx-m-44;
        background-color: #FB9A9A;
        border-radius: 0 0 $apx-m-15 $apx-m-15;
  
        &:hover { background: #FB9A9A; }
  
        p {
      
          font-size: $apx-m-14;
          line-height: $apx-m-16;
          top: $apx-m-13;
        
        }
      
      }
    
    }
  
  }

}