//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.textfield-button {

  width: $apx-155;
  height: $apx-40;
  position: absolute;
  bottom: $apx-30;
  right: $apx-10;

}