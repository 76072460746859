//подключение apx
@import '../../../styles/apx.scss';

.popup-yandex {

  &__subtitle {
  
    margin: $apx-15 0;
    color: #595959;
    font-weight: 400;
    font-size: $apx-16;
    line-height: $apx-20;
  
  }

  &__image {
  
    height: auto;
    width: $apx-550;
    margin-bottom: $apx-20;
  
  }

  .button {
  
    height: $apx-50;
    margin-bottom: $apx-15;
  
  }

}

@media screen and (max-width: 800px) {

  .popup-yandex {

    width: 100%;
    padding: $apx-m-12;

    &__subtitle {
    
      margin-top: $apx-m-25;
      margin-bottom: $apx-m-15;
      font-size: $apx-m-14;
      line-height: $apx-m-18;
    
    }
  
    &__image {
    
      height: auto;
      width: $apx-m-335;
      border-radius: $apx-m-8;
      margin-bottom: $apx-m-12;
    
    }

    .button {
  
      height: $apx-m-46;
      text-transform: lowercase;
      border-radius: $apx-m-8;
      
      &:not(:last-child) { margin-bottom: $apx-m-12; }
    
    }
  
  }

}