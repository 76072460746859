//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';

.uniq-metric {

  width: 100%;
  min-height: 0;
  max-height: $apx-271;
  flex-grow: 1 !important;
  display: flex !important;
  margin-top: $apx-20;
  border-radius: $apx-15;
  border: $apx-2 solid #F4F4F4;
  flex-direction: column;

  &__header {
  
    width: 100%;
    height: $apx-80;
    padding: $apx-25 $apx-35;
    border-bottom: $apx-2 solid #F4F4F4;

    p {
    
      white-space: nowrap;
      color: #676767;
      font-weight: 600;
      font-size: $apx-24;
      line-height: $apx-28;
    
    }

    &__addbutton {
    
      top: $apx-5;
      width: $apx-45;
      height: $apx-45;
    
    }
  
  }

  &__body {
  
    padding: $apx-28 $apx-30;

    &__row {

      margin-bottom: $apx-20;

      &__name, &__type {

        color: #676767;
        font-family: Roboto;
        white-space: nowrap;
        font-size: $apx-20;
        line-height: $apx-24;

      }
    
      &__name {

        width: $apx-265;
        font-weight: 600 !important;
      
      }

      &__type {
      
        width: $apx-165;
        font-weight: 400 !important;
      
      }

      &__button {
      
        top: -$apx-8;
        left: $apx-8;
        width: $apx-250;
        height: $apx-37;
        p { font-weight: 700; }
      
      }
    
    }
  
  }

}

@media screen and ( max-width: 800px ) {

  .uniq-metric {

    width: 100%;
    max-height: unset;
    margin-top: unset;
    border-radius: unset;
    margin-left: unset;
    border: unset;

    margin-bottom: $apx-m-180;
    border-top: $apx-m-2 solid #dbdbdb55;
  
    &__header {
    
      height: unset;
      padding: $apx-m-18 $apx-m-20 $apx-m-16;
      border-bottom: $apx-m-2 solid #dbdbdb55;
  
      p {
      
        font-size: $apx-m-20;
        line-height: $apx-m-24;
      
      }
  
      &__addbutton {
      
        top: -$apx-m-1;
        left: $apx-m-2;
        width: $apx-m-30;
        height: $apx-m-30;
      
      }
    
    }
  
    &__body {

      padding: $apx-m-18 $apx-m-10 0; 
  
      &__row {
  
        margin-bottom: $apx-20;
        flex-direction: column;

        &__rower { width: 100%; }

        &__liner {

          opacity: 0.4;
          height: $apx-m-2;
          width: $apx-m-365;
          margin-left: -$apx-m-20;
          margin-bottom: $apx-m-15;
          background: #dbdbdb;

        }
  
        &__name, &__type {

          font-size: $apx-m-18;
          line-height: $apx-m-22;
          margin-bottom: $apx-m-15;
  
        }
      
        &__name {
  
          width: 100%;
        
        }
  
        &__type {
        
          right: unset;
          position: relative;
          text-align: right;
          width: $apx-m-150;
        
        }
  
        &__button {
        
          top: unset;
          left: unset;
          width: 100%;
          height: $apx-m-42;
          border-radius: $apx-m-4;
          margin-bottom: $apx-m-24;
          
          p {

            font-size: $apx-m-18;
            line-height: $apx-m-22;

          }
        
        }
      
      }
    
    }
  
  }

}