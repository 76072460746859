//подключение apx и tailwind
@import '../../../styles/apx.scss';
@import '../../../styles/tailwind.scss';


.materials__columns {
  
  & svg {

    cursor: pointer;
    width: 100%;
    width: $apx-36;

  }

  flex-grow: 1;
  flex-shrink: 1;
  margin-top: $apx-34;
  flex-direction: column;
  border: 1px solid #F4F4F4;
  border-radius: $apx-15;

  & + .materials__columns { margin-left: $apx-20; }

  .materials-head {

    color: #676767;
    font-size: $apx-23;
    line-height: $apx-25;
    font-family: Montserrat;
    font-weight: 600 !important;
    
    &__subhead {

      font-size: $apx-21;
      line-height: $apx-21;
      padding: $apx-5;
      padding-left: $apx-25;
      margin-top: -$apx-5;
      margin-bottom: $apx-15;

    }

  }

  .materials__header {

    height: $apx-80;
    padding: $apx-26;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #F4F4F4;

  }

  &_content {

    display: flex;
    flex-direction: column;
    width: calc( 100% - 5px );
    color: #616161;
    margin-top: $apx-10;
    padding: $apx-26;
    height: $apx-600;
    overflow: auto;

  }

  &-types {
    
    width: $apx-360;

  }

  &-subtypes {

    width: $apx-420;

  }

  &-parameters {

    width: $apx-535;

  }   

  &-available {

    width: $apx-500;

    p {

      margin-left: $apx-24;
      font-family: Roboto;
      font-size: $apx-21;
      font-weight: 400;
      color: #494545;

    }

  }

  .columns-container {

    padding: 0;

  }

.companies-available__container {

  justify-content: flex-start !important;

    svg {

      width: $apx-65;
      width: $apx-31;

    }

  } 

}

.fields-container {

  display: flex;

  &__content {
  
    width: $apx-230;
    margin-right: $apx-25;

  }

}

.button-del {

  width: 100%;
  min-height: $apx-46;

}