//подключение apx
@import './apx.scss';
@import './tailwind.scss';
//сброс стандартных стилей
* { 
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-decoration: none;
}

a {
  color: inherit;
}

html,
body,
#root {

  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fefefe;

}

input,
input:focus {

  outline: none;
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

 
}

//общие стили
svg, img {

  width: 100%;
  height: 100%;

}

.desktop { display: block !important; }
.mobile { display: none !important; }
.desktop-inline { display: inline !important; }
.desktop-flex { display: flex !important; }
.mobile-flex { display: none !important; }

.error {

  user-select: none;
  text-align: center;
  color: #ef4646;
  font-size: 18px;
  font-weight: 400;

}

.disabled {

  opacity: 0.4;
  pointer-events: none;

}

.pointer {

  cursor: pointer;

}

input[type="file"] {
  
  position: absolute;
  left: -200vw;
  
}
 
//стили скролбара
::-webkit-scrollbar {
  width: $apx-10;
  height: $apx-8;
}
::-webkit-scrollbar-track {
  border-radius: $apx-25;
  background-color: #F4F4F4 !important;
}
::-webkit-scrollbar-thumb {
  border-radius: $apx-25;
  background-color: #CAF1C9 !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #c6ecc5 !important;
}

//шрифты
.montserrat {
  top: $apx-1;
  position: relative;
  line-height: $apx-25;
  font-family: Montserrat;
}

//подключение шрифтов
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@media screen and ( max-width: 800px ) {

  // body, #root { overflow-x: hidden; }

  html, body { 
    position: fixed;
    height: 100%;
    // overflow: hidden;
  }

  #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  .desktop { display: none !important; }
  .mobile { display: block !important; }
  .desktop-flex { display: none !important; }
  .mobile-flex { display: flex !important; }

}