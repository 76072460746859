//подключение apx
@import '../../../styles/apx.scss';

.popup__content {

    width: 100%;
    height: 100%;
    width: $apx-608;
    padding: $apx-23 $apx-28 $apx-5;
    border: 0.5px solid #F0F0F0;

}

.popup__button__generate-pass__employee {

  height: $apx-48;
  padding: 0 $apx-15;
  margin-top: $apx-22;
  margin-left: $apx-18;

}

a 
.popup__button__generate-pass__employee { margin: $apx-4 0 $apx-16 !important; }

.popup__button__generate-pass__send {

  position: relative;
  display: flex;
  white-space: nowrap;
  width: 100% !important;
  height: $apx-50;
  padding: 0 $apx-15;
  margin: $apx-5 0 $apx-15 !important;

  .desktop { margin: 0 $apx-4; }

}

@media screen and (max-width: 800px) {

  .popup__content__employee,
  .popup__content__maintext {

    width: 100%;
    border: unset;
    padding: $apx-m-20 $apx-m-15;

  }

  .popup__button__generate-pass__employee {

    height: $apx-48;
    padding: 0 $apx-15;
    margin-top: $apx-22;
    margin-left: $apx-18;

  }

  a .popup__button__generate-pass__employee {
    margin: $apx-4 0 $apx-16 !important;
  }

  .popup__button__generate-pass__send {

    position: relative;
    width: 100% !important;
    height: $apx-m-42;
    padding: 0 $apx-m-15;
    margin: $apx-m-5 0 $apx-m-15 !important;

  }

}