//подключение apx
@import '../../../styles/apx.scss';

.checkitem {

  width: 100%;
  display: flex;
  padding: 0.48vw 0 0.64vw 1.35vw;
  justify-content: space-between;

  &-text {

    color: #8E8E8E;
    font-weight: 400;
    font-family: Roboto;
    font-size: $apx-22;
    line-height: $apx-24;
    margin: $apx-10 0 $apx-10;

  }

  &__name {

    width: $apx-330;
    padding-right: 1.73vw;
    
  }

  &__price { width: 35%; }

  & + .checkitem { margin-top: $apx-10; }

  &:hover {
    background: #e5ffe5;
    p, input {
      color: #296935 !important;
      font-weight: 500;
    }
  }

}

.checkitembox {

  width: 100%;
  height: 100%;
  top: 0;
  right: $apx-72 !important;

  &--price,
  input { z-index: 2; }

  input, 
  input::placeholder,
  .checkitem__costtitle {

    background: unset;
    color: #8E8E8E;
    font-weight: 400;
    font-size: $apx-20;
    line-height: $apx-24;
    right: 0;

  }

  &__subtitle { 
    
    right: 0 !important;
    width: $apx-70;
    p { text-align: left; }
  
  }

  &__costtitle,
  &__costtitle__input { 
    
    top: 0;
    right: $apx-98;
    font-size: $apx-20;
    line-height: $apx-28;
  
  }

  &__costtitle__input { right: $apx-54; }

  &__costtitle { 
    
    cursor: pointer;
    user-select: none;
    color: #8E8E8E !important;
    font-size: $apx-22;
    line-height: $apx-20;
    font-weight: 400;
    font-family: Roboto;
  
  }

  &--price { text-align: right; }

}

@media screen and ( max-width: 800px ) {

  .checkitem {

    width: $apx-m-375;
    padding: $apx-m-15 $apx-m-20 0;
    display: grid !important;
    grid-template-columns: "1fr 1fr";
    grid-template-areas: "name name" "coststuff costwork";
    &:nth-child(even) { background: rgba(244, 244, 244, 0.6); }

    &:hover {

      background: unset !important;
      &:nth-child(even) { background: rgba(244, 244, 244, 0.6) !important; }

      p, input {

        color: #8E8E8E !important;
        font-weight: 400;

      }

      .checkitembox__costitle__text {
      
        font-weight: 400 !important;
        &:hover { font-weight: 400 !important; }
      
      }

    }

    &__costtitle {
       
      display: flex;
      width: 100%;
      max-width: 46vw;
      min-width: 46vw;
      font-weight: 500 !important;
      &:hover { font-weight: 500 !important;}

      span {

        font-weight: 400;
        font-size: $apx-m-18;
        line-height: $apx-m-20;
        color: #8E8E8E !important;

      }
    
    }
  
    &-text {

      font-weight: 500;
      font-size: $apx-m-16;
      line-height: $apx-m-16;
      margin: $apx-m-5 0 $apx-m-5;
  
    }
  
    &__name {
  
      grid-area: name;
      width: $apx-m-350;
      font-weight: 500 !important;
      font-size: $apx-m-18;
      line-height: $apx-m-20;
      margin-bottom: $apx-m-25;
      &:hover { font-weight: 500 !important;}
    
    }
    
  
    &__price {
      
      width: 100%;
      height: $apx-m-35;
      &__stuff { grid-area: coststuff; }
      &__work { grid-area: costwork; }
    
    }
  
    & + .checkitem { margin-top: $apx-m-5; }
    &:hover { background: unset; }
  
  }
  
  .checkitembox {

    position: unset !important;
  
    input, 
    input::placeholder,
    .checkitem__costtitle {

      left: 0;
      width: $apx-m-100;
      font-weight: 500;
      font-size: $apx-m-18;
      line-height: $apx-m-20;
      text-align: left !important;
  
    }
  
    &__subtitle {
      
      width: unset;
      white-space: nowrap;
      top: $apx-m-10;
    
    }
  
    &__costtitle,
    &__costtitle__input { 

      position: unset !important;
      font-size: $apx-m-20;
      line-height: $apx-m-28;
    
    }
  
    &__costtitle__input { right: unset; }
  
    &__costtitle { 

      font-size: $apx-m-18;
      line-height: $apx-m-20;
      font-weight: 400;
      font-family: Roboto;

      &__text {
      
        top: -$apx-m-15;
        position: absolute;
        font-family: Roboto;
        font-weight: 500 !important;
        font-size: $apx-m-12;
        line-height: $apx-m-14;
        margin-bottom: $apx-m-2;
        color: rgba(142, 142, 142, 0.8);
        text-align: left !important;
        &:hover { font-weight: 400 !important;}
      
      }

      &__unit { display: none !important; }
    
    }

    &--price { text-align: left !important; }
 
  }

}