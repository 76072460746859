//подключение apx
@import '../../../styles/apx.scss';

.listitem {

  cursor: pointer;
  width: 100%;
  height: $apx-68;
  margin: $apx-5 0;
  padding: $apx-10;
  text-align: center;
  align-items: center;

  & + .materials-head__subhead {

    padding-top: $apx-48 !important;
  
  }
  
  &__img {

    width: 100%;
    height: 100%;
    width: $apx-51;
    height: $apx-51;
    padding: $apx-4;
    border-radius: $apx-12;

  }

  p {

    color: #494545;
    font-weight: 400;
    font-size: $apx-22;
    line-height: $apx-25;
    padding-top: $apx-4;
    margin-left: $apx-25;
    font-family: Roboto;

  }

  &--active {

    height: $apx-68;
    border-radius: $apx-12;
    border: $apx-2 solid #79D877;
    background-color: #79d87744;

  }

}